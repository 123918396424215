function loginEvent(userId){
  if(userId)
  {
    // AF('pba', 'setCustomerUserId', userId);
    // AF('pba', 'event', {eventType: 'EVENT', eventName: 'af_login', eventValue: {'customUserId':userId} });

    AF('pba', 'setCustomerUserId', userId);
    AF('pba', 'event', {eventType: 'EVENT', eventName: 'af_login', eventValue: {'customUserId':userId} });
  }
}

function signupEvent(userId){
  if(userId)
  {
    // AF('pba', 'setCustomerUserId', userId);
    // AF('pba', 'event', {eventType: 'EVENT', eventName: 'af_complete_registration', eventValue: {'customUserId':userId} });

    AF('pba', 'setCustomerUserId', userId);
    AF('pba', 'event', {eventType: 'EVENT', eventName: 'af_complete_registration', eventValue: {'customUserId':userId} });
  }
}